import React, {useEffect, useState} from 'react';
import { Space, Table, Tag} from "antd";
import Modal from "antd/es/modal/Modal";
import {
    CheckCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined
} from "@ant-design/icons";
import {useFetching} from "../../hooks/useFetching";
import VoucherService from "../../API/VoucherService";

// balance: 0
// code: "OQBHCPAKOWO"
// contentVoucherId: "1"
// coverId: "1"
// createdAt: "2024-06-05T15:34:50.534477+05:00"
// greeting: "ДАВАЙ"
// id: "d87a1bc8-c80e-40c9-878b-63afe3506887"
// recipientId: "ee3a06c7-4f84-4f52-b30b-d12ddd5372ee"
// recipientName: "НУра"
// senderId: "d2f135be-3f95-4092-aab6-5258ac02c2fe"
// senderName: "Олег"
// status: "INACTIVE"
// validTill: "2025-06-05"
// viewed: true

const Voucher = () => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Баланс',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: 'Код',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'ID получателя',
            dataIndex: 'recipientId',
            key: 'recipientId',
        },
        {
            title: 'ID отправителя',
            dataIndex: 'senderId',
            key: 'senderId',
        },
        {
            title: 'Приветствие',
            dataIndex: 'greeting',
            key: 'greeting',
        },
        {
            title: 'Имя получателя',
            dataIndex: 'recipientName',
            key: 'recipientName',
        },
        {
            title: 'Имя отправителя',
            dataIndex: 'senderName',
            key: 'senderName',
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Действителен до',
            dataIndex: 'validTill',
            key: 'validTill',
        },
    ];
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15,
    });
    const [VoucherList, setVoucherList] = useState([]);
    const [fetchVoucherList, isLoading] = useFetching(async (pagination) => {
        const changedPagination = {
            page: pagination.current - 1,
            size: pagination.pageSize
        };
        const {data} = await VoucherService.getAll(changedPagination);
        setVoucherList(data.items);
        setPagination({
            current: data.page + 1,
            pageSize: pagination.pageSize,
            total: data.totalItems
        });
    });

    const handleTableChange = (pagination) => {
        setPagination(pagination);
        fetchVoucherList(pagination);
    }
    
    useEffect(() => {
        fetchVoucherList(pagination);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Table 
                columns={columns} 
                dataSource={VoucherList} 
                rowKey='id' 
                loading={isLoading} 
                pagination={pagination}
                onChange={handleTableChange}
            />
        </>
    );
};

export default Voucher;